
































import { Component, Mixins } from "vue-property-decorator";
import HelperDinamicallyForm from "@/components/DinamicallyForm/HelperDinamicallyForm";
import { datatypes } from "@/components/DataTableGenerico/datatypes";
import datos_empresaModule from "@/store/modules/datos_empresa-module";
import { datos_empresa } from "@/shared/dtos/datos_empresa";
import { API } from "@/shared/api";
import { MessageService } from "@/shared/services/message-service";

@Component({
  components: {
    DocumentsHandler: () => import("../Documentos/DocumentsHandler.vue"),
  },
})
export default class datos_empresaFormulario extends Mixins(
  HelperDinamicallyForm
) {
  public item_pantalla: datos_empresa = new datos_empresa();

  public async OnCreated() {
    this.ShowLoading();
    /*
    if (this.getparamId().HayParametro) {
      await datos_empresaModule.getdatos_empresa(this.getparamId().Parametro);
      this.item_pantalla = datos_empresaModule.datos_empresa;
    } else {
      try {
        await datos_empresaModule.getdatos_empresas();
        if (datos_empresaModule.datos_empresas.length > 0) {
          this.item_pantalla = datos_empresaModule.datos_empresas[0];
        }
      } catch {}
    }
*/

    if (this.getparamId().HayParametro) {
      await datos_empresaModule.getdatos_empresa(this.getparamId().Parametro);
      this.item_pantalla = datos_empresaModule.datos_empresa;
    }

    //Para la clonacion automatica
    this.BasicElement = this.item_pantalla;
    this.HideLoading();
  }

  public get_url() {
    try {
      return {
        url: API.datos_empresa + "/documents/" + this.item_pantalla.id_cliente,
        url_download:
          API.webApiBasedatos_empresa + this.item_pantalla.id_cliente,
      };
    } catch (error) {
      return {
        url: "",
        url_download: "",
      };
    }
  }

  public CreateFields() {
    //Empezamos a crear una pantalla
    //configuracion de la clonacion automatica

    this.AddFormItemDataDefaultString(
      this.item_pantalla.nombre,
      "Nombre",
      "nombre",
      60
    )
      .isRequired()
      .Dimesiones(12, 10, 4);

    this.AddFormItemDataDefaultString(
      this.item_pantalla.nif,
      "Nif",
      "nif",
      50
    ).Dimesiones(12, 10, 4);

    this.AddFormItemDataDefaultString(
      this.item_pantalla.codigo_cuenta_cotizacion,
      "Código cuenta cotización",
      "codigo_cuenta_cotizacion",
      50
    ).Dimesiones(12, 10, 4);
    this.AddFormItemDataDefaultString(
      this.item_pantalla.nombre_donde_fichan,
      "Texto usuario pantalla fichaje",
      "nombre_donde_fichan",
      50
    ).Dimesiones(12, 10, 4);
    this.AddFormItemDataDefaultString(
      this.item_pantalla.notas,
      "Notas",
      "notas",
      50
    )
      .Dimesiones(12, 12, 12)
      .CreateTextArea();

    this.AddFormItemDataDefaultSlot(
      null,
      "Documentos",
      "documentos",
      500
    ).Dimesiones(12, 12, 12);
  }

  public Insertar(object: datos_empresa) {
    datos_empresaModule.guardardatos_empresa(object).then(() => {
      this.AllSaveOk();
    });
  }

  public Actualizar(object: datos_empresa) {
    object.id = this.item_pantalla.id;
    object.id_cliente = this.item_pantalla.id_cliente;
    object.name_imagen = this.item_pantalla.name_imagen;

    datos_empresaModule.modificardatos_empresa(object).then(() => {
      this.AllSaveOk(); //guardo correctamente y me cierro
    });
  }
}
